import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomePageView.vue'
import sourceData from '@/data.json'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    alias:"/home"
  },
  // {path:"/home", redirect:"/"},
  
  {
    path:'/protected',
    name: 'protected',
    components: {
      default: ()=> import("@/views/ProtectedView.vue"),
      LeftSidebar: ()=> import("@/components/LeftSidebar.vue")
    },
    meta:{
      requiresAuth:true,
    }
  },
  {
    path:'/editor',
    name: 'editor',
    components: {
      default: ()=> import("@/views/EditorShow.vue"),
      // LeftSidebar: ()=> import("@/components/LeftSidebar.vue")
    },
    meta:{
      requiresAuth:true,
    }
  },
  {
    path:'/login',
    name:'login',
    component: ()=> import("@/views/LoginView.vue"),
  },
  {
    path:'/about',
    name:'about',
    component: ()=> import("@/views/AboutShow.vue"),
  },
  {
    path:'/history',
    name:'history',
    component: ()=> import("@/views/HistoryShow.vue"),
  },
  {
    path:'/event',
    name:'event',
    component: ()=> import("@/views/EventShow.vue"),
  },
  {
    path:'/leaders',
    name:'leaders',
    component: ()=> import("@/views/LeadersShow.vue"),
  },
  {
    path:'/giving',
    name:'giving',
    component: ()=> import("@/views/GivingShow.vue"),
  },

  {
    path:'/invoices',
    name: 'invoices',
    components: {
      default: ()=> import("@/views/InvoicesView.vue"),
      LeftSidebar: ()=> import("@/components/LeftSidebar.vue")
    },
    meta:{
      requiresAuth:true,
    }
  },
  {
    path: "/example/:id(\\d+)?", // regular expretions *, +, ?
    component: ()=> import("@/views/LoginView.vue"),
  },
  
  {
    path: '/destination/:id/:slug',
    name: 'destination.show',
    component: ()=> import(/* webpackChunkName: "destination" */ "@/views/DestinationShow.vue"),
    props: route=>({...route.params, id: parseInt(route.params.id)}),
    beforeEnter(to){
      const exists =  sourceData.destinations.find(
        destination => destination.id === parseInt(to.params.id)
      )
      if(!exists) return {
        name:'NotFound',
        // allows Keeping the URL while rendering a different Page
        params:{pathMatch: to.path.split('/').slice(1)},
        query: to.query,
        hash: to.hash


      }
    },
    children:[
      {
        path: ':experienceSlug',
        name: 'experience.show',
        component: ()=> import(/* webpackChunkName: "experience" */ "@/components/ExperienceShow.vue"),
        props: route=>({...route.params, id: parseInt(route.params.id)})
      }
    ]

  },
  {
    path:'/:pathMatch(.*)*',
    name:'NotFound',
    component: ()=> import('@/views/NotFound.vue')
  }
  

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || new Promise((resolve)=>{
      setTimeout(()=> resolve({top:0, behavior:'smooth'}), 300)
    })

  }
})

router.beforeEach((to)=>{
  if(to.meta.requiresAuth && !window.user){
    // need to login if not already logged in
    return {name:'login', query:{redirect: to.fullPath}}
  }
})

export default router
