<template>
    <div id="footer">
        <div class="main_cont">
            <div class="title_cont">
                <h2>Aglican Diocese of central Zambia</h2>
            </div>
            <div class="info_cont"> 
                <div class="info_list">
                    <div class="address">
                        P.O BOX 70172.
                        <br>
                        Ndola, Zambia
                        <br>
                    </div>
                    <div class="phone">
                        Office: +260 212 612431
                    </div>
                    <div class="email">
                        centraldiocesezm@gmail.com
                    </div>
                

                </div>
                <div class="sitemap_list">
                    About
                    <br>
                    History
                    <br>
                    Events
                    <br>
                    Give
                    <br>
                </div>
            </div>
            <div class="copyright_cont">
                <div>@ 2023 Aglican Diocese of central Zambia</div>
            </div>
        </div>
        
  
  </div>
</template>

<script>

import sourceData from '@/data.json'

    export default {
        data(){
            return {
                destinations:sourceData.destinations
            }
        }
    }

</script>

<style>
    .main_cont{
        width: 90%;
        margin: auto;
    }
    .title_cont{
        padding: 20px 0;
    }
    .info_cont{
        display: flex;
        justify-content: space-around;
    }
    .info_list{

    }
    .address{
        padding: 10px 0;
    }
    .phone{
        padding: 10px 0;
    }
    .email{
        padding: 10px 0;
    }
    .sitemap_list{

    }
    .copyright_cont{
        text-align: center;
        padding: 20px 0;
    }
</style>