<template>
  <div class="homeCont">
    <div class="welcome">
      <div class="welcomeImg" :style="image">
        <div class=" text-amber-300 w-10/12 text-3xl font-bold shadow-xl p-2 bg-blue-400 bg-opacity-80 rounded">Welcome to Anglican Diocese of central Zambia</div>
      </div>
      <!-- <img class="welcomeImg" :src="`/images/frontPicture.jpg`"> -->
      
    </div>
    
    <div class="secondCont1">
      <div class="col_two_elements">
        <!-- <div class="col_blank"></div> -->
        <AppLink class="element" to="/about">
          <img class="element_img" :src="`/images/${destinations[0].image}`" :alt="destinations[0].name">
          <div class="element_text">
            <div>{{destinations[0].name}}</div>
          </div>          
        </AppLink>
        <AppLink to="/history" class="element">          
          <img class="element_img" :src="`/images/${destinations[1].image}`" :alt="destinations[1].name">
          <div class="element_text">
            <div>{{destinations[1].name}}</div>
          </div>
        </AppLink>
        <!-- <div class="col_blank"></div> -->
        <AppLink to="/event" class="element">          
          <img class="element_img" :src="`/images/${destinations[2].image}`" :alt="destinations[2].name">
          <div class="element_text">
            <div>{{destinations[2].name}}</div>
          </div>
        </AppLink>
        <!-- <div class="col_blank"></div> -->
        <AppLink to="/giving" class="element">          
          <img class="element_img" :src="`/images/${destinations[3].image}`" :alt="destinations[3].name">
          <div class="element_text">
            <div>{{destinations[3].name}}</div>
          </div>
        </AppLink>
      </div>

    
      
    </div>

    <div class="thirdCont">
      <div class="element_2">
        <div class="element_2_text_cont">
          <h2 class="element_2_text">Service Info</h2>
          <div class="text_line_element_2">
            <div class="line_element_2"></div>
          </div>
            <div class="button_cont_element_2">
              <button>More Info</button>
          </div>
        </div>
        <div class="element_2_image_cont">
          <img class="element_img" :src="`/images/${destinations[0].image}`" :alt="destinations[0].name">
        </div>
      </div>
      <div class="element_2 rev">
        <div class="element_2_text_cont">
          <h2 class="element_2_text">Service Info</h2>
          <div class="text_line_element_2">
            <div class="line_element_2"></div>
          </div>
            <div class="button_cont_element_2">
              <button>More Info</button>
          </div>
        </div>
        <div class="element_2_image_cont">
          <img class="element_img" :src="`/images/${destinations[0].image}`" :alt="destinations[0].name">
        </div>
      </div>
    </div>
    
    
    
  </div>
</template>

<script>
import sourceData from '@/data.json'

import { isNavigationFailure, NavigationFailureType } from 'vue-router'

export default {
  data(){

    return{
      destinations: sourceData.destinations,
      image: `background-image: url("/images/frontPicture.PNG")`

    }
  },
  methods:{
    addDynamicRoute(){
     this.$router.addRoute({
        name: "dynamic",
        path: "/dynamic",
        component: () => import("@/views/LoginView.vue")
      })
    },
    removeDynamicRoute(){
      this.$router.removeRoute("dynamic")
    },
    async triggerRouterError(){
      const navigationFailure = await this.$router.push('/')
      if(isNavigationFailure(navigationFailure, NavigationFailureType.duplicated)){
        // things went wrong
        console.log(navigationFailure.to)

        console.log(navigationFailure.from)

      }else{
        // all is well
      }
    }
  }
}

</script>

<style>
  .col_two_elements{
    padding-bottom: 50px;
    padding-top: 50px;
    display: flex;
    place-content: space-evenly;
    flex-flow: wrap;
  }

  .col_blank{
    width: 8.3%;
  }
  .element{
    width: 41.6%;
    position: relative;
  }
  .element:hover{
    transform: scale(0.9);
  }
  .element:active{
    transform: scale(0.8);
  }
  .element_img{
    width: 100%;
    height: 90%;
  }
  .element_text{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    /* font-size: 2.5em; */
    color: white;
    top: 45%;
    transform: translateY(-50%);
    pointer-events: none;
    /* position: absolute; */
    text-align: center;
    width: 100%;
    height: 90%;
    font-weight: bold;
    font-size: 5vw;
  }

  .thirdCont{
    width: 80%;
    margin: auto;
  }
  .element_2{
    width: 100%;
    display: flex;
    place-content: space-evenly;
    flex-flow: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
  }  
  .element_2_text_cont{
    width: 41.6%;
    text-align-last: center;
    place-self: center;
  }
  .element_2_text{
    padding: 0;
    margin: 0;
  }
  .element_2_image_cont{
    width: 41.6%;
  }
  .text_line_element_2{
    max-width: 110px;
    display: inline-block;
    width: 100%;
  }
  .line_element_2{
    border-color: transparent;
    border-bottom-color: #CCCCCC;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
  }
  .rev{
    flex-flow: row-reverse;
  }

  .homeCont{
    width: 100%;
    
  }
  .secondCont1{
    width: 80%;
    margin: auto;
  }
  .welcome{
    display: flex;
    width: 100%;
    max-height: 300px;
    background-color: #475c76;
  }
  .welcomeImg{
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    background-size: auto;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .welcomeText{
    color: gold;
    /* background-color: #475c76; */
    /* font-size: 1.9em; */
    font-size: 5ex;
    max-width: 50%;
    font-weight: bold;
  }
</style>
