<template>
    <div id="nav">
        <div id="left_nav">
            <AppLink id="logo" to="/"> <img id="logoIcon" :src="`/images/churchLogoWithText.png`"></AppLink>
        </div>
        <div id="right_nav">
            <!-- <AppLink 
                v-for="destination in destinations"
                :key="destination.id"
                :to="{name: 'destination.show', params:{id: destination.id, slug: destination.slug}}"
            >
                {{destination.name}}
            </AppLink> -->
            <div class=" hidden sm:block">
                <AppLink to="/about">About</AppLink>
                <AppLink to="/history">History</AppLink>
                <AppLink to="/event">Events</AppLink>
                <AppLink to="/leaders">Leaders</AppLink>
                <AppLink to="/giving">Give</AppLink>

                <AppLink :to="{name: 'protected'}">Dashboard</AppLink>
            </div>
            <div class=" sm:hidden relative">
                <div class=" active:scale-90" :class="{'change':showdropdown}" @click="clickedHumMenu(this)">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
                <div v-if="showdropdown" class=" absolute flex flex-col w-fit right-0 p-4 dropmenu ">
                    <AppLink @click="showMenu(false)" to="/about">About</AppLink>
                    <AppLink @click="showMenu(false)" to="/history">History</AppLink>
                    <AppLink @click="showMenu(false)" to="/event">Events</AppLink>
                    <AppLink @click="showMenu(false)" to="/leaders">Leaders</AppLink>
                    <AppLink @click="showMenu(false)" to="/giving">Give</AppLink>

                    <AppLink @click="showMenu(false)" :to="{name: 'protected'}">Dashboard</AppLink>
                </div>
            </div>
            
            <!-- <AppLink to="https://itsart02.github.io/"> SkateTime</AppLink> -->
        </div>
    
  
  
  </div>
</template>

<script>

import sourceData from '@/data.json'

    export default {
        data(){
            return {
                destinations:sourceData.destinations,
                showdropdown:false
            }
        },
        methods:{
            showMenu(show){
                if(show){
                    this.showdropdown = true
                }
                else{
                    this.showdropdown = false
                }
            },
            clickedHumMenu(x){
                this.showMenu(!this.showdropdown)
                x.classList.toggle("change");
            }
        },
    }

</script>
<style scoped>
.dropmenu{
    background: #eeeeee;
}
.bar1, .bar2, .bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}
/* Rotate first bar */
.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.change .bar2 {opacity: 0;}

/* Rotate last bar */
.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}
</style>
